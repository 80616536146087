// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;
$hover-color: #dfe5ed;

$table-text-color: #1a1a1a;

html body{
    font-size: $font-size-base;
}

h1{font-size: 2.8rem!important; line-height: initial!important;}
h2{font-size: 2.4rem!important; line-height: initial!important;}
h3{font-size: 2.2rem!important; line-height: initial!important;}
h4{font-size: 1.8rem!important; line-height: initial!important;}
h5{font-size: 1.7rem!important; line-height: initial!important;}
h6{font-size: 1.6rem!important; line-height: initial!important;}
