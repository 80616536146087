// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

@tailwind base;
@tailwind components;
@tailwind utilities;

.content{min-height: calc(100vh - 2.5rem)!important;}

.align-center{align-items: center;}

div[x-data="LivewireUIModal()"]{z-index: 9999999;}
div[x-data="LivewireUIModal()"] .w-full.bg-white{max-width: 50vw; width: initial}

.documents-grid .side-menu-item:hover,
.documents-grid .side-menu-item.active{background: $hover-color; color: var(--white)}

div[x-ref="popover"] {
    justify-content: flex-start;
}

@media (prefers-color-scheme: dark) {
    .dark\:text-white{color: $table-text-color !important;}
}

.data-table{
    table{
        border-collapse:separate;
        border-spacing:0 5px;
    }
    tbody{
        -webkit-border-vertical-spacing: 10px;
    }
    .filter_row td{
        background: rgb(var(--color-slate-100)/var(--tw-bg-opacity)) !important;
    }
}

@media only screen and (max-width: 767px) {
    .content{margin-top:4rem; min-height: calc(100vh - 6.5rem) !important;}
    .mobile-menu .scrollable{width: 100vw; z-index: 120;}
}

[id^=datatable-] {
    .shadow {
        box-shadow: none !important;
    }

    table {
        border-spacing: 0 5px;
        border-collapse: separate;

        > thead {
            background: transparent;
            th {
                background: transparent;
            }

            .uppercase {
                text-transform: unset;
                font-weight: initial;
                font-size: 13px;
                color: #333;
            }
        }

        > tbody {
            > tr {
                &:hover > td {
                    background: #e2e8f0;
                }

                > td {
                    background: #fff;

                    &:first-child {
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                    }

                    &:last-child {
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                    }
                }
            }
        }

        > tfoot {
            tr {
                background: transparent!important;
                td {
                    background: transparent!important;
                }
            }
        }
    }
}
